/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderLoading", "renderDownForMaintenance", "renderOrderError"] }] */

import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
  Container,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Col,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  format,
  set,
  roundToNearestMinutes,
  addMinutes,
  addDays,
  getMinutes,
  isBefore,
  isAfter,
  isToday,
  isSameDay,
} from 'date-fns';
import axios from 'axios';
import { DebounceInput } from 'react-debounce-input';

import loadingSpinner from '../../images/Double Ring-1s-200px.svg';

let menu = {};

const normalizePhoneInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 10) return currentValue;
  return currentValue.slice(0, 10);
};

const maskPhoneInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 3) return currentValue;
  if (currentValue.length === 3) return `${currentValue}-`;
  if (currentValue.length <= 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  if (currentValue.length === 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}-`;
  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

const normalizeZipInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 5) return currentValue;
  return currentValue.slice(0, 5);
};

const normalizeCardNumberInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 19) return currentValue;
  return currentValue.slice(0, 19);
};

const normalizeCardValidThruInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 2) return currentValue;
  return currentValue.slice(0, 2);
};

const maskCardValidThruMonthInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length === 1 && currentValue > 1) {
    return `0${currentValue}`;
  }
  if (currentValue > 12) {
    return '12';
  }
  return currentValue;
};

const maskCardValidThruYearInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue < 20) {
    return '20';
  }
  return currentValue;
};

const normalizeCVCInput = (value) => {
  const currentValue = value.replace(/[^\d]/g, '');
  if (currentValue.length <= 4) return currentValue;
  return currentValue.slice(0, 4);
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.onOrderTypeBtnClick = this.onOrderTypeBtnClick.bind(this);
    this.onContinueBtnClick = this.onContinueBtnClick.bind(this);
    this.onStartOverBtnClick = this.onStartOverBtnClick.bind(this);
    this.onDecreaseItemBtnClick = this.onDecreaseItemBtnClick.bind(this);
    this.onIncreaseItemBtnClick = this.onIncreaseItemBtnClick.bind(this);
    this.onRemoveItemClick = this.onRemoveItemClick.bind(this);
    this.getMenu = this.getMenu.bind(this);
    this.sendEmailConfirmation = this.sendEmailConfirmation.bind(this);
    this.setInCartStatus = this.setInCartStatus.bind(this);
    this.calculateDeliveryCost = this.calculateDeliveryCost.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTipInputChange = this.handleTipInputChange.bind(this);
    this.handleBillingCheckboxChange = this.handleBillingCheckboxChange.bind(this);
    this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
    this.handlePhoneInputBlur = this.handlePhoneInputBlur.bind(this);
    this.handleZipInputChange = this.handleZipInputChange.bind(this);
    this.handleCardNumberInputChange = this.handleCardNumberInputChange.bind(this);
    this.handleCardValidThruInputChange = this.handleCardValidThruInputChange.bind(this);
    this.handleCardValidThruInputBlur = this.handleCardValidThruInputBlur.bind(this);
    this.handleCVCInputChange = this.handleCVCInputChange.bind(this);
    this.handleQuantityTextChange = this.handleQuantityTextChange.bind(this);
    this.handlePickupDateChange = this.handlePickupDateChange.bind(this);
    this.handlePickupTimeChange = this.handlePickupTimeChange.bind(this);
    this.validateOrderTime = this.validateOrderTime.bind(this);
    this.checkOrderSlots = this.checkOrderSlots.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.renderMenuItems = this.renderMenuItems.bind(this);
    this.renderCart = this.renderCart.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.renderDownForMaintenance = this.renderDownForMaintenance.bind(this);
    this.renderOrderError = this.renderOrderError.bind(this);
    this.renderSelectOrderType = this.renderSelectOrderType.bind(this);
    this.renderOrderForm = this.renderOrderForm.bind(this);
    this.renderOrderConfirmation = this.renderOrderConfirmation.bind(this);
    this.renderPickupCheckout = this.renderPickupCheckout.bind(this);
    this.renderDeliveryAddressCheckout = this.renderDeliveryAddressCheckout.bind(this);
    this.renderDeliveryPaymentCheckout = this.renderDeliveryPaymentCheckout.bind(this);
    this.renderOrderComplete = this.renderOrderComplete.bind(this);

    this.state = {
      currentView: 'loading',
      orderType: '',
      cart: {},
      totalItems: 0,
      totalCost: 0.0,
      deliveryFee: 0,
      deliveryTip: 0,
      pickupDateTime: roundToNearestMinutes(addMinutes(new Date(), 38), { nearestTo: 15 }),
      pickupTimeInvalid: false,
      orderSlotsFull: false,
      closestTimes: [],
      pickupTimeSelected: true,
      specialInstructions: '',
      deliveryInstructions: '',
      deliveryFirstName: '',
      deliveryLastName: '',
      deliveryAddress: '',
      deliveryAddress2: '',
      deliveryCity: '',
      deliveryState: 'CA',
      deliveryZip: '',
      contactlessDeliveryCheckbox: false,
      firstName: '',
      lastName: '',
      billingAddress: '',
      billingCity: '',
      billingState: 'CA',
      billingZip: '',
      billingAddressCheckbox: false,
      phone: '',
      email: '',
      cardNumber: '',
      cardValidThruMonth: '',
      cardValidThruYear: '',
      cardCVC: '',
      orderNumber: '',
      formErrors: {},
      paymentDeclined: false,
      invalidAddress: false,
      confirmationEmailSent: false,
      disableSubmitBtn: false,
    };
  }

  componentDidMount() {
    // check if the initial pickupDateTime is after closing time
    // if true, add one day to pickupDateTime and set time to first order
    const { pickupDateTime } = this.state;
    const firstOrder = set(pickupDateTime, { hours: 11, minutes: 15, seconds: 0, milliseconds: 0 });
    const lastOrder = set(pickupDateTime, { hours: 19, minutes: 1, seconds: 0, milliseconds: 0 });

    if (isBefore(pickupDateTime, firstOrder)) {
      // before opening, set time to 11:15
      this.setState({
        pickupDateTime: set(pickupDateTime, { hours: 11, minutes: 15 }),
      });
    } else if (isAfter(pickupDateTime, lastOrder)) {
      // after close, set time to 11:15 on the next day
      this.setState({
        pickupDateTime: addDays(set(pickupDateTime, { hours: 11, minutes: 15 }), 1),
      });
    }

    // get menu from the API
    this.getMenu();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleTipInputChange(e) {
    const { name, value } = e.target;

    const normalizedValue = parseFloat(
      value
        .replace(/(.*){1}/, '0$1')
        .replace(/[^\d]/g, '')
        .replace(/(\d\d?)$/, '.$1'),
    ).toFixed(2);

    this.setState({
      [name]: normalizedValue,
    });
  }

  handleBillingCheckboxChange(e) {
    const { target } = e;
    const value = target.checked;
    const { name } = target;

    if (!value) {
      this.setState({
        [name]: value,
      });
    } else {
      const { deliveryAddress, deliveryCity, deliveryState, deliveryZip } = this.state;
      this.setState({
        [name]: value,
        // firstName: deliveryFirstName,
        // lastName: deliveryLastName,
        billingAddress: deliveryAddress,
        billingCity: deliveryCity,
        billingState: deliveryState,
        billingZip: deliveryZip,
      });
    }
  }

  handlePhoneInputChange(e) {
    const { value } = e.target;

    const normalizedValue = normalizePhoneInput(value);

    this.setState({
      phone: normalizedValue,
    });
  }

  handlePhoneInputBlur(e) {
    const { value } = e.target;

    const maskedValue = maskPhoneInput(value);

    this.setState({
      phone: maskedValue,
    });
  }

  handleZipInputChange(e) {
    const { name, value } = e.target;

    const normalizedValue = normalizeZipInput(value);

    this.setState({
      [name]: normalizedValue,
    });
  }

  handleCardNumberInputChange(e) {
    const { value } = e.target;

    const normalizedValue = normalizeCardNumberInput(value);

    this.setState({
      cardNumber: normalizedValue,
    });
  }

  handleCardValidThruInputChange(e) {
    const { name, value } = e.target;

    const normalizedValue = normalizeCardValidThruInput(value);

    this.setState({
      [name]: normalizedValue,
    });
  }

  handleCardValidThruInputBlur(e) {
    const { name, value } = e.target;

    let normalizedValue = '';
    if (name === 'cardValidThruMonth') {
      normalizedValue = maskCardValidThruMonthInput(value);
    } else {
      normalizedValue = maskCardValidThruYearInput(value);
    }

    this.setState({
      [name]: normalizedValue,
    });
  }

  handleCVCInputChange(e) {
    const { value } = e.target;

    const normalizedValue = normalizeCVCInput(value);

    this.setState({
      cardCVC: normalizedValue,
    });
  }

  handleQuantityTextChange(e) {
    const str = e.target.value.replace(/\D/g, '');

    const { cart, currentView } = this.state;
    let { totalItems, totalCost } = this.state;
    let newValue = 0;

    if (str !== '') {
      newValue = parseInt(str, 10);
    }

    if (currentView === 'orderConfirmation' && newValue < 1) {
      newValue = 1;
    }

    if (e.target.dataset.sizeid == null) {
      const totalItemsAdded = newValue - cart[e.target.dataset.id].quantity;
      cart[e.target.dataset.id].quantity = newValue;
      totalItems += totalItemsAdded;
      totalCost += totalItemsAdded * cart[e.target.dataset.id].price;
      this.setState({ cart, totalItems, totalCost });
    } else {
      const totalItemsAdded =
        newValue - cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity;
      cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity = newValue;
      totalItems += totalItemsAdded;
      totalCost +=
        totalItemsAdded * cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].sizePrice;
      this.setState({ cart, totalItems, totalCost });
    }
  }

  handlePickupDateChange(e) {
    let { pickupDateTime } = this.state;
    const [y, m, d] = e.target.value.split('-');

    pickupDateTime = set(pickupDateTime, { year: y, month: m - 1, date: d });

    // check if selected day is today and after opening
    // and update time if needed
    // const firstOrder = set(pickupDateTime, { hours: 11, minutes: 15, seconds: 0, milliseconds: 0 });

    // if (isToday(pickupDateTime) && isAfter(firstOrder, pickupDateTime)) {
    //   roundToNearestMinutes(addMinutes(new Date(), 38), { nearestTo: 15 });
    // }

    this.setState({ pickupDateTime });
  }

  handlePickupTimeChange(e) {
    const { pickupDateTime } = this.state;
    const [h, m] = e.target.value.split(':');

    this.setState({
      pickupDateTime: set(pickupDateTime, { hours: h, minutes: m }),
      pickupTimeSelected: true,
    });
  }

  onOrderTypeBtnClick(e, orderType) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    this.setState({ currentView: 'orderForm', orderType });
  }

  onContinueBtnClick(e, section) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { currentView } = this.state;
    const newSection = section;

    if (currentView === 'orderForm') {
      this.setState({
        currentView: newSection,
        pickupTimeInvalid: false,
        orderSlotsFull: false,
        pickupTimeSelected: true,
      });
      this.setInCartStatus();
    } else {
      this.setState({ currentView: newSection });
    }
  }

  onStartOverBtnClick(e) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    // check if the initial pickupDateTime is after closing time
    // if true, add one day to pickupDateTime and set time to first order
    let pickupDateTime = roundToNearestMinutes(addMinutes(new Date(), 38), { nearestTo: 15 });
    const firstOrder = set(pickupDateTime, { hours: 11, minutes: 15, seconds: 0, milliseconds: 0 });
    const lastOrder = set(pickupDateTime, { hours: 19, minutes: 1, seconds: 0, milliseconds: 0 });

    if (isBefore(pickupDateTime, firstOrder)) {
      // before opening, set time to 11:15
      pickupDateTime = set(pickupDateTime, { hours: 11, minutes: 15 });
    } else if (isAfter(pickupDateTime, lastOrder)) {
      // after close, set time to 11:15 on the next day
      pickupDateTime = addDays(set(pickupDateTime, { hours: 11, minutes: 15 }), 1);
    }

    // reset state to default and display loading spinner
    this.setState({
      currentView: 'loading',
      orderType: '',
      cart: {},
      totalItems: 0,
      totalCost: 0.0,
      deliveryFee: 0,
      deliveryTip: 0,
      pickupDateTime,
      pickupTimeInvalid: false,
      orderSlotsFull: false,
      closestTimes: [],
      pickupTimeSelected: true,
      specialInstructions: '',
      deliveryInstructions: '',
      deliveryFirstName: '',
      deliveryLastName: '',
      deliveryAddress: '',
      deliveryAddress2: '',
      deliveryCity: '',
      deliveryState: 'CA',
      deliveryZip: '',
      contactlessDeliveryCheckbox: false,
      firstName: '',
      lastName: '',
      billingAddress: '',
      billingCity: '',
      billingState: 'CA',
      billingZip: '',
      billingAddressCheckbox: false,
      phone: '',
      email: '',
      cardNumber: '',
      cardValidThruMonth: '',
      cardValidThruYear: '',
      cardCVC: '',
      orderNumber: '',
      formErrors: {},
      paymentDeclined: false,
      invalidAddress: false,
      confirmationEmailSent: false,
      disableSubmitBtn: false,
    });

    // call getMenu to reset cart to default
    this.getMenu();
  }

  onDecreaseItemBtnClick(e) {
    e.preventDefault();
    e.target.blur();

    const { cart, currentView } = this.state;
    let { totalItems, totalCost } = this.state;

    if (currentView === 'orderForm') {
      if (e.target.dataset.sizeid == null) {
        if (cart[e.target.dataset.id].quantity !== 0) {
          cart[e.target.dataset.id].quantity -= 1;
          totalItems -= 1;
          totalCost -= cart[e.target.dataset.id].price;
          this.setState({ cart, totalItems, totalCost });
        }
      } else if (cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity !== 0) {
        cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity -= 1;
        totalItems -= 1;
        totalCost -= cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].sizePrice;
        this.setState({ cart, totalItems, totalCost });
      }
    } else if (e.target.dataset.sizeid == null) {
      if (cart[e.target.dataset.id].quantity > 1) {
        cart[e.target.dataset.id].quantity -= 1;
        totalItems -= 1;
        totalCost -= cart[e.target.dataset.id].price;
        this.setState({ cart, totalItems, totalCost });
      }
    } else if (cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity > 1) {
      cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity -= 1;
      totalItems -= 1;
      totalCost -= cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].sizePrice;
      this.setState({ cart, totalItems, totalCost });
    }
  }

  onIncreaseItemBtnClick(e) {
    e.preventDefault();
    e.target.blur();

    const { cart } = this.state;
    let { totalItems, totalCost } = this.state;

    if (e.target.dataset.sizeid == null) {
      if (cart[e.target.dataset.id].quantity >= 0) {
        cart[e.target.dataset.id].quantity += 1;
        totalItems += 1;
        totalCost += cart[e.target.dataset.id].price;
        this.setState({ cart, totalItems, totalCost });
      } else {
        cart[e.target.dataset.id].quantity = 0;
        this.setState({ cart });
      }
    } else if (cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity >= 0) {
      cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity += 1;
      totalItems += 1;
      totalCost += cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].sizePrice;
      this.setState({ cart, totalItems, totalCost });
    } else {
      cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity = 0;
      this.setState({ cart });
    }
  }

  onRemoveItemClick(e) {
    e.preventDefault();

    const { cart } = this.state;
    let { totalItems, totalCost } = this.state;

    if (e.target.dataset.sizeid == null) {
      totalItems -= cart[e.target.dataset.id].quantity;
      totalCost -= cart[e.target.dataset.id].quantity * cart[e.target.dataset.id].price;
      cart[e.target.dataset.id].quantity = 0;
      cart[e.target.dataset.id].inCart = false;
    } else {
      totalItems -= cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity;
      totalCost -=
        cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity *
        cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].sizePrice;
      cart[e.target.dataset.id].sizes[e.target.dataset.sizeid].quantity = 0;
      cart[e.target.dataset.id].inCart = false;
    }

    this.setState({ cart, totalItems, totalCost });
    this.setInCartStatus();
  }

  async getMenu() {
    const url = `${process.env.REACT_APP_API_URL}/menu`;
    axios
      .get(url)
      .then((response) => {
        menu = response.data;
        this.setState({ currentView: 'orderType', cart: menu.items });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ currentView: 'maintenance' });
      });
  }

  setInCartStatus() {
    const { cart } = this.state;

    let i;
    let j;

    for (i = 0; i < cart.length; i++) {
      if (cart[i].sizes.length === 0) {
        if (cart[i].quantity > 0) {
          cart[i].inCart = true;
        } else {
          cart[i].inCart = false;
        }
      } else {
        cart[i].inCart = false;
        for (j = 0; j < cart[i].sizes.length; j++) {
          if (cart[i].sizes[j].quantity > 0) {
            cart[i].inCart = true;
          }
        }
      }
    }

    this.setState({ cart });
  }

  sendEmailConfirmation() {
    const url = `${process.env.REACT_APP_API_URL}/sendConfirmation`;

    const {
      orderNumber,
      orderType,
      email,
      firstName,
      lastName,
      deliveryFirstName,
      deliveryLastName,
      pickupDateTime,
      totalCost,
      deliveryFee,
      deliveryTip,
    } = this.state;

    axios
      .post(url, {
        orderNumber,
        orderType,
        email,
        firstName,
        lastName,
        deliveryFirstName,
        deliveryLastName,
        pickupDateTime,
        totalCost,
        deliveryFee,
        deliveryTip,
      })
      .then((response) => {
        this.setState({ confirmationEmailSent: true });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  calculateDeliveryCost(e) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    let { deliveryFirstName, deliveryLastName, formErrors } = this.state;

    const { deliveryAddress, deliveryCity, deliveryState, deliveryZip, phone, email, totalCost } =
      this.state;

    // setup variable for form validation
    let formValid = true;
    formErrors = {};

    // remove special characters from input
    deliveryFirstName = deliveryFirstName.replace(/[^a-zA-Z-]/g, '');
    deliveryLastName = deliveryLastName.replace(/[^a-zA-Z-]/g, '');

    // check if firstName is empty
    if (deliveryFirstName.length === 0) {
      // set FormErrors.firstName to true && formValid to false
      formErrors.deliveryFirstName = true;
      formValid = false;
    }

    // check if lastName is empty
    if (deliveryLastName.length === 0) {
      // set FormErrors.lastName to true && formValid to false
      formErrors.deliveryLastName = true;
      formValid = false;
    }

    // check if deliveryAddress is empty
    if (deliveryAddress.length === 0) {
      // set FormErrors.deliveryAddress to true && formValid to false
      formErrors.deliveryAddress = true;
      formValid = false;
    }

    // check if deliveryCity is empty
    if (deliveryCity.length === 0) {
      // set FormErrors.deliveryCity to true && formValid to false
      formErrors.deliveryCity = true;
      formValid = false;
    }

    // check if deliveryZip is empty
    if (deliveryZip.length !== 5) {
      // set FormErrors.deliveryZip to true && formValid to false
      formErrors.deliveryZip = true;
      formValid = false;
    }

    // check if phone is empty
    if (phone.length !== 12) {
      // set FormErrors.phone to true && formValid to false
      formErrors.phone = true;
      formValid = false;
    }

    // check if email is empty
    if (email.length === 0) {
      // set FormErrors.email to true && formValid to false
      formErrors.email = true;
      formValid = false;
    }

    // check if email is valid
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      )
    ) {
      // set FormErrors.email to true && formValid to false
      formErrors.email = true;
      formValid = false;
    }

    // if form inputs invalid, set update state to display errors
    if (!formValid) {
      this.setState({ formErrors });
    } else {
      const destinations = `${deliveryAddress.replace(/\s/g, '+')},${deliveryCity.replace(
        /\s/g,
        '+',
      )},${deliveryState}`;

      let deliveryFee = 0;

      const url = `${process.env.REACT_APP_API_URL}/distance`;

      axios
        .get(url, {
          params: {
            destinations,
          },
        })
        .then((response) => {
          const deliveryDistance = (
            response.data.rows[0].elements[0].distance.value * 0.000621371192
          ).toFixed(1);

          // address is not valid or address is outside delivery range
          if (deliveryDistance > 150) {
            this.setState({ invalidAddress: true });
            return;
          }

          // calculate deliveryFee
          if (totalCost < 50) {
            deliveryFee = deliveryDistance - 5 > 0 ? 10 + (deliveryDistance - 5) * 2 : 10;
          } else if (totalCost < 100) {
            deliveryFee = deliveryDistance - 8 > 0 ? 20 + (deliveryDistance - 8) * 2.5 : 20;
          } else {
            deliveryFee = deliveryDistance - 10 > 0 ? 30 + (deliveryDistance - 10) * 3 : 30;
          }
          this.setState({
            formErrors,
            deliveryFee,
            firstName: deliveryFirstName,
            lastName: deliveryLastName,
            currentView: 'payment',
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ formErrors, invalidAddress: true });
        });
    }
  }

  validateOrderTime(e) {
    // * CHECK IF ORDER SLOT IS OPEN
    // * DISPLAY TIME BUTTONS IF NOT OPEN
    // * SET currentView TO NEXT SCREEN IF VALID

    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { pickupDateTime, orderType, totalCost } = this.state;
    let { deliveryTip } = this.state;

    const url = `${process.env.REACT_APP_API_URL}/order-slot`;

    axios
      .post(url, { pickupDateTime })
      .then((response) => {
        // CHECK FOR OPEN ORDER SLOT
        if (
          response.data === null ||
          (orderType === 'delivery' && response.data.delivery < 2) ||
          (orderType === 'pickup' && response.data.pickup < 3)
        ) {
          // slot is valid, calculate delivery tip
          // and set currentView to checkout
          if (totalCost > 0 && orderType === 'delivery') {
            deliveryTip = Math.ceil(totalCost * 0.1).toFixed(2);
          }

          this.setState({
            currentView: 'checkout',
            orderSlotsFull: false,
            pickupTimeInvalid: false,
            deliveryTip,
          });

          this.setInCartStatus();
        } else {
          // slot is full, call checkOrderSlots()
          this.setState({
            pickupTimeSelected: false,
            pickupTimeInvalid: false,
          });

          this.checkOrderSlots();
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ currentView: 'maintenance' });
      });
  }

  checkOrderSlots() {
    const { pickupDateTime, orderType } = this.state;

    const url = `${process.env.REACT_APP_API_URL}/order-slot/date`;

    axios
      .post(url, { pickupDateTime })
      .then((response) => {
        // calculate open slots
        const currentSlots = [...response.data];

        // console.log('***Response***');
        // console.log(response);

        let openingTime = set(pickupDateTime, {
          hours: 11,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        });

        const openingHour = 11;

        // setup array for time slots
        const closestTimes = [];
        const timeSlots = [];
        // 11:00 AM is [0], 7:00 PM is [108]
        const totalSlots = 96;
        for (let i = 0; i <= totalSlots; i++) {
          timeSlots.push({ idx: i, time: openingTime, delivery: 0, pickup: 0 });
          openingTime = addMinutes(openingTime, 5);
        }
        const selectedTimeIdx =
          (pickupDateTime.getHours() - openingHour) * 12 + pickupDateTime.getMinutes() / 5;
        // console.log(pickupDateTime);
        // console.log(`Selected Time Index: ${selectedTimeIdx}`);

        // update array with current order slots
        currentSlots.forEach((slot) => {
          const slotTime = new Date(slot.pickupDateTime);
          const slotTimeIdx = (slotTime.getHours() - openingHour) * 12 + slotTime.getMinutes() / 5;
          // console.log(slotTimeIdx);
          // fix in case there's an order before opening in the db
          if (slotTimeIdx >= 0) {
            timeSlots[slotTimeIdx].delivery = slot.delivery;
            timeSlots[slotTimeIdx].pickup = slot.pickup;
          }
        });

        // console.log(timeSlots);

        // calculate closest open order slots
        // set starting index if after opening
        const firstOrder = set(pickupDateTime, {
          hours: 11,
          minutes: 15,
          seconds: 0,
          milliseconds: 0,
        });
        let startingIdx = 3;

        // pickupDateTime is today and restaurant is open, only display times 35+ minutes in the future
        if (isToday(pickupDateTime) && isAfter(pickupDateTime, firstOrder)) {
          const nextValidTime = roundToNearestMinutes(addMinutes(new Date(), 33), { nearestTo: 5 });
          // console.log(`nextValidTime: ${nextValidTime}`);
          // console.log(`nextValidTime: ${nextValidTime.getHours() - openingHour}`);
          startingIdx =
            (nextValidTime.getHours() - openingHour) * 12 + nextValidTime.getMinutes() / 5;
          // console.log(`startingIdx ${startingIdx}`);
        }

        // make sure startingIdx is greater than 3
        if (startingIdx < 3) {
          startingIdx = 3;
        }

        // console.log(timeSlots[startingIdx]);
        // CHECK FOR OPEN ORDER SLOT
        for (let i = startingIdx; i < timeSlots.length; i++) {
          if (
            (orderType === 'pickup' && timeSlots[i][orderType] < 3) ||
            (orderType === 'delivery' && timeSlots[i][orderType] < 2)
          ) {
            if (closestTimes.length < 4) {
              closestTimes.push({
                time: timeSlots[i].time,
                availableIndex: i,
              });
            } else if (
              Math.abs(i - selectedTimeIdx) <
              Math.abs(closestTimes[0].availableIndex - selectedTimeIdx)
            ) {
              closestTimes.push({
                time: timeSlots[i].time,
                availableIndex: i,
              });
              closestTimes.shift();
            }
          }
        }

        this.setState({ orderSlotsFull: true, closestTimes });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ currentView: 'maintenance' });
      });
  }

  submitOrder(e) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { currentView } = this.state;

    this.setState({ currentView: 'loading', disableSubmitBtn: true });

    function filterInCart(value) {
      return value.inCart === true;
    }

    function filterInCartSize(value) {
      return value.quantity > 0;
    }

    const {
      orderType,
      cart,
      specialInstructions,
      deliveryInstructions,
      totalItems,
      totalCost,
      deliveryFee,
      deliveryTip,
      deliveryFirstName,
      deliveryLastName,
      pickupDateTime,
      contactlessDeliveryCheckbox,
    } = this.state;

    let { firstName, lastName, formErrors } = this.state;

    const {
      billingAddress,
      billingCity,
      billingState,
      billingZip,
      deliveryAddress,
      deliveryAddress2,
      deliveryCity,
      deliveryZip,
      phone,
      email,
      cardNumber,
      cardValidThruMonth,
      cardValidThruYear,
      cardCVC,
    } = this.state;

    // setup variable for form validation
    let formValid = true;
    formErrors = {};

    // remove special characters from input
    firstName = firstName.replace(/[^a-zA-Z-]/g, '');
    lastName = lastName.replace(/[^a-zA-Z-]/g, '');

    // check if firstName is empty
    if (firstName.length === 0) {
      // set FormErrors.firstName to true && formValid to false
      formErrors.firstName = true;
      formValid = false;
    }

    // check if lastName is empty
    if (lastName.length === 0) {
      // set FormErrors.lastName to true && formValid to false
      formErrors.lastName = true;
      formValid = false;
    }

    // check if billingAddress is empty
    if (billingAddress.length === 0) {
      // set FormErrors.billingAddress to true && formValid to false
      formErrors.billingAddress = true;
      formValid = false;
    }

    // check if billingCity is empty
    if (billingCity.length === 0) {
      // set FormErrors.billingCity to true && formValid to false
      formErrors.billingCity = true;
      formValid = false;
    }

    // check if billingZip is empty
    if (billingZip.length !== 5) {
      // set FormErrors.billingZip to true && formValid to false
      formErrors.billingZip = true;
      formValid = false;
    }

    // check if phone is empty
    if (phone.length !== 12) {
      // set FormErrors.phone to true && formValid to false
      formErrors.phone = true;
      formValid = false;
    }

    // check if email is empty
    if (email.length === 0) {
      // set FormErrors.email to true && formValid to false
      formErrors.email = true;
      formValid = false;
    }

    // check if email is valid
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      )
    ) {
      // set FormErrors.email to true && formValid to false
      formErrors.email = true;
      formValid = false;
    }

    // check if cardNumber is empty
    if (cardNumber.length < 12) {
      // set FormErrors.cardNumber to true && formValid to false
      formErrors.cardNumber = true;
      formValid = false;
    }

    // check if cardValidThruMonth is empty
    if (cardValidThruMonth.length !== 2) {
      // set FormErrors.cardValidThruMonth to true && formValid to false
      formErrors.cardValidThruMonth = true;
      formValid = false;
    }

    // check if cardValidThruYear is empty
    if (cardValidThruYear.length !== 2) {
      // set FormErrors.cardValidThruYear to true && formValid to false
      formErrors.cardValidThruYear = true;
      formValid = false;
    }

    // check if cardCVC is empty
    if (cardCVC.length < 3) {
      // set FormErrors.cardCVC to true && formValid to false
      formErrors.cardCVC = true;
      formValid = false;
    }

    // if form inputs invalid, set update state to display errors
    // otherwise submit order to api and await response
    if (!formValid) {
      this.setState({ currentView, formErrors, disableSubmitBtn: false });
    } else {
      const filteredCartItems = cart.filter(filterInCart);

      // copy cart so original object is not modified
      const cartCopy = JSON.parse(JSON.stringify(filteredCartItems));

      let i;

      for (i = 0; i < cartCopy.length; i++) {
        if (cartCopy[i].sizes.length > 0) {
          // filter only sizes w/ quantity > 0 and update object
          const filteredSizeItems = cartCopy[i].sizes.filter(filterInCartSize);
          cartCopy[i].sizes = filteredSizeItems;
        }
      }

      const cardValidThru = `${cardValidThruMonth}${cardValidThruYear}`;

      if (cartCopy.length !== 0) {
        // check if selected time is still open
        const slotURL = `${process.env.REACT_APP_API_URL}/order-slot`;

        axios
          .post(slotURL, { pickupDateTime })
          .then((slotResponse) => {
            // CHECK FOR OPEN ORDER SLOT
            if (
              slotResponse.data === null ||
              (orderType === 'delivery' && slotResponse.data.delivery < 2) ||
              (orderType === 'pickup' && slotResponse.data.pickup < 3)
            ) {
              // slot open, submit order
              const url = `${process.env.REACT_APP_API_URL}/order`;
              axios
                .post(url, {
                  orderType,
                  firstName,
                  lastName,
                  address: billingAddress,
                  city: billingCity,
                  state: billingState,
                  zip: billingZip,
                  deliveryFirstName,
                  deliveryLastName,
                  deliveryAddress,
                  deliveryAddress2,
                  deliveryCity,
                  deliveryZip,
                  phone,
                  email,
                  items: cartCopy,
                  specialInstructions,
                  deliveryInstructions,
                  contactlessDeliveryCheckbox,
                  pickupDateTime,
                  totalItems,
                  totalCost,
                  deliveryFee,
                  deliveryTip,
                  cardNumber,
                  cardValidThru,
                  cardCVC,
                })
                .then((response) => {
                  if (response.data.orderError || response.data.dateError) {
                    if (response.data.orderError) {
                      this.setState({ currentView: 'orderError' });
                    } else {
                      this.setState({
                        currentView: 'orderConfirmation',
                        pickupTimeInvalid: true,
                        pickupTimeSelected: false,
                        disableSubmitBtn: false,
                      });
                    }
                  } else {
                    const orderNumber = `0000000${response.data.orderNumber}`.slice(-7);
                    this.setState({ orderNumber, currentView: 'orderComplete' });
                  }
                })
                .catch((error) => {
                  console.error(error);
                  this.setState({
                    currentView,
                    paymentDeclined: true,
                    disableSubmitBtn: false,
                  });
                });
            } else {
              // slot taken, set currentView to checkout
              this.setState({
                currentView: 'orderConfirmation',
                pickupTimeInvalid: true,
                pickupTimeSelected: false,
                disableSubmitBtn: false,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({ currentView: 'maintenance' });
          });
      } else {
        console.error('No Items to Submit to Server!');
        this.setState({ currentView: 'orderError' });
      }
    }
  }

  renderMenuItems(type) {
    function filterType(value) {
      return value.category === type;
    }

    const filteredMenuItems = menu.items.filter(filterType);

    return (
      <div>
        {filteredMenuItems.map((item) => (
          <Row key={`${item.id}-item`} className={`item${item.id % 2 ? '' : '-even'}`}>
            <Col md="6" xs="12">
              <div className="item-title">{item.name}</div>
              <div className="item-description">
                {item.description}
                {item.panDescription && <div className="item-serving">{item.panDescription}</div>}
              </div>
            </Col>
            {item.sizes.length === 0 && (
              <Col md="3" xs="6">
                <div className="item-price mr-n4">${item.price.toFixed(2)}</div>
              </Col>
            )}
            {item.sizes.length === 0 && (
              <Col md="3" xs="6">
                <InputGroup className="item-quantity">
                  <InputGroupAddon addonType="prepend">
                    <Button data-id={item.id} onClick={(e) => this.onDecreaseItemBtnClick(e)}>
                      {' '}
                      -{' '}
                    </Button>
                  </InputGroupAddon>
                  <input
                    data-id={item.id}
                    type="text"
                    size="5"
                    value={this.state.cart[item.id].quantity}
                    placeholder="0"
                    onChange={(e) => this.handleQuantityTextChange(e)}
                  />
                  <InputGroupAddon addonType="append">
                    <Button data-id={item.id} onClick={(e) => this.onIncreaseItemBtnClick(e)}>
                      {' '}
                      +{' '}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            )}

            {item.sizes.length > 0 && (
              <Col md="3" xs="6">
                {item.sizes.map((size) => (
                  <div className="item-price mr-n4" key={`${item.id}-${size.sizeid}-desc`}>
                    <span className="item-serving">{size.sizeDescription}</span>$
                    {size.sizePrice.toFixed(2)}
                  </div>
                ))}
              </Col>
            )}

            {item.sizes.length > 0 && (
              <Col md="3" xs="6">
                {item.sizes.map((size) => (
                  <InputGroup
                    className="item-quantity"
                    key={`${item.id}-${size.sizeid}-inputGroup`}
                  >
                    <InputGroupAddon addonType="prepend">
                      <Button
                        data-id={item.id}
                        data-sizeid={size.sizeid}
                        onClick={(e) => this.onDecreaseItemBtnClick(e)}
                      >
                        {' '}
                        -{' '}
                      </Button>
                    </InputGroupAddon>
                    <input
                      data-id={item.id}
                      data-sizeid={size.sizeid}
                      type="text"
                      size="5"
                      value={this.state.cart[item.id].sizes[size.sizeid].quantity}
                      placeholder="0"
                      onChange={(e) => this.handleQuantityTextChange(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        data-id={item.id}
                        data-sizeid={size.sizeid}
                        onClick={(e) => this.onIncreaseItemBtnClick(e)}
                      >
                        {' '}
                        +{' '}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                ))}
              </Col>
            )}
          </Row>
        ))}
      </div>
    );
  }

  renderCart() {
    const { orderType } = this.state;

    function filterInCart(value) {
      return value.inCart === true;
    }

    function filterInCartSize(value) {
      return value.quantity > 0;
    }

    const { cart, specialInstructions } = this.state;
    const filteredCartItems = cart.filter(filterInCart);

    // copy cart so original object is not modified
    const cartCopy = JSON.parse(JSON.stringify(filteredCartItems));

    let i;

    for (i = 0; i < cartCopy.length; i++) {
      if (cartCopy[i].sizes.length > 0) {
        // filter only sizes w/ quantity > 0 and update object
        const filteredSizeItems = cartCopy[i].sizes.filter(filterInCartSize);
        cartCopy[i].sizes = filteredSizeItems;
      }
    }

    if (cartCopy.length !== 0) {
      return (
        <div>
          {cartCopy.map((item, idx) => (
            <Row key={item.id} className={`item${idx % 2 ? '' : '-even'}`}>
              <Col md="6" xs="12">
                <div className="checkout-item-title">{item.name}</div>
              </Col>
              {item.sizes.length === 0 && (
                <Col md="3" xs="6">
                  <div className="item-price mr-n4">${item.price.toFixed(2)}</div>
                </Col>
              )}
              {item.sizes.length === 0 && (
                <Col md="3" xs="6">
                  <InputGroup className="item-quantity">
                    <InputGroupAddon addonType="prepend">
                      <Button data-id={item.id} onClick={(e) => this.onDecreaseItemBtnClick(e)}>
                        {' '}
                        -{' '}
                      </Button>
                    </InputGroupAddon>
                    <DebounceInput
                      data-id={item.id}
                      debounceTimeout={1000}
                      type="text"
                      size="5"
                      value={this.state.cart[item.id].quantity}
                      onChange={(e) => this.handleQuantityTextChange(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button data-id={item.id} onClick={(e) => this.onIncreaseItemBtnClick(e)}>
                        {' '}
                        +{' '}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="remove-item">
                    <Button
                      color="link"
                      data-id={item.id}
                      onClick={(e) => this.onRemoveItemClick(e)}
                    >
                      remove item
                    </Button>
                  </div>
                </Col>
              )}

              {item.sizes.length > 0 && (
                <Col md="3" xs="6">
                  {item.sizes.map((size) => (
                    <div
                      className="item-price-cart mr-n4"
                      key={`${item.id}-${size.sizeid}-descCart`}
                    >
                      <span className="item-serving">{size.sizeDescription}</span>$
                      {size.sizePrice.toFixed(2)}
                    </div>
                  ))}
                </Col>
              )}

              {item.sizes.length > 0 && (
                <Col md="3" xs="6">
                  {item.sizes.map((size) => (
                    <div key={`${item.id}-${size.sizeid}-inputGroupCart`}>
                      <InputGroup className="item-quantity">
                        <InputGroupAddon addonType="prepend">
                          <Button
                            data-id={item.id}
                            data-sizeid={size.sizeid}
                            onClick={(e) => this.onDecreaseItemBtnClick(e)}
                          >
                            {' '}
                            -{' '}
                          </Button>
                        </InputGroupAddon>
                        <DebounceInput
                          data-id={item.id}
                          data-sizeid={size.sizeid}
                          debounceTimeout={1000}
                          type="text"
                          size="5"
                          value={this.state.cart[item.id].sizes[size.sizeid].quantity}
                          onChange={(e) => this.handleQuantityTextChange(e)}
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            data-id={item.id}
                            data-sizeid={size.sizeid}
                            onClick={(e) => this.onIncreaseItemBtnClick(e)}
                          >
                            {' '}
                            +{' '}
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                      <div className="remove-item">
                        <Button
                          color="link"
                          data-id={item.id}
                          data-sizeid={size.sizeid}
                          onClick={(e) => this.onRemoveItemClick(e)}
                        >
                          remove item
                        </Button>
                      </div>
                    </div>
                  ))}
                </Col>
              )}
            </Row>
          ))}
          <Row className="order-special-instructions">
            <Col xs={{ size: 10, offset: 1 }}>
              <div className="order-total">
                <div className="order-total-title">Special Instructions for Order</div>
                {orderType === 'delivery' && (
                  <p>
                    Please leave delivery instructions for{' '}
                    <span style={{ fontWeight: 700 }}>StreetSmart Messengers</span> on the next
                    page.
                  </p>
                )}
                <div>
                  <FormGroup>
                    <Input
                      type="textarea"
                      className="order-special-instructions-input"
                      id="specialInstructions"
                      name="specialInstructions"
                      rows="2"
                      maxLength="100"
                      value={specialInstructions}
                      placeholder="max 100 characters"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div>
        <h3 style={{ color: '#dc3545' }}>
          The cart is empty, please click the back button and add some items!
        </h3>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="header">
        <Container>
          <Row>
            <Col xs="12" className="tops" />
          </Row>
          <Row>
            <Col xs="12">
              <h1>Tito's Tacos Express</h1>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderDownForMaintenance() {
    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">ONLINE ORDERING WILL RETURN SOON!</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="text-center item-top">
                  <Col xs="12">
                    <h3>SORRY FOR THE INCONVENIENCE</h3>
                    <p>
                      {/* Call{' '}
                      <a href="tel:1-310-391-5780">
                        <b>310-391-5780</b>
                      </a>{' '}
                      to place your Food Order, whilst  */}
                      Online Food Ordering is temporarily suspended and being upgraded for your
                      convenience.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderOrderError() {
    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">ERROR CREATING ORDER</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="text-center item-top">
                  <Col xs="12">
                    <h3>SORRY FOR THE INCONVENIENCE</h3>
                    <p>Please refresh the page and try again.</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderSelectOrderType() {
    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">ORDER TYPE</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="order-type">
                <Row className="align-items-center justify-content-center">
                  <Col className="mt-4 mb-4" xs="12">
                    <h4>Please Select Order Type</h4>
                    <p>
                      Delivery is provided by{' '}
                      <span style={{ fontWeight: 700 }}>StreetSmart Messengers</span>. The following
                      delivery fee will be calculated and added to the order total.
                    </p>
                    <Row style={{ background: '#ffffff' }}>
                      <Col xs="6" className="text-right">
                        Orders under $50:
                      </Col>
                      <Col xs="6" className="text-left">
                        $2.00 per mile with a $10 minimum charge
                      </Col>
                      <Col xs="6" className="text-right">
                        Orders between $50 and $100:{' '}
                      </Col>
                      <Col xs="6" className="text-left">
                        $2.50 per mile with a $20 minimum charge
                      </Col>
                      <Col xs="6" className="text-right">
                        Orders over $100:{' '}
                      </Col>
                      <Col xs="6" className="text-left">
                        $3.00 per mile with a $30 minimum charge
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4" xs="12">
                    <Button block onClick={(e) => this.onOrderTypeBtnClick(e, 'delivery')}>
                      DELIVERY
                    </Button>
                  </Col>
                  <Col md="4" xs="12">
                    <Button block onClick={(e) => this.onOrderTypeBtnClick(e, 'pickup')}>
                      PICK UP
                    </Button>
                  </Col>
                  {/* <Col className="mt-4" xs="12">
                    <p style={{ fontSize: '1.2rem', fontWeight: 700 }}>
                      Due to overwhelming demand, placing pick up orders online is temporarily
                      unavailable. As always, in person ordering is available at the restaurant!
                    </p>
                  </Col> */}
                  <Col className="mt-2" xs="12">
                    <p style={{ fontSize: '1.2rem', fontWeight: 700 }}>
                      Please note the last available pick up time is 7:00 PM and restaurant closes
                      at 8:00 PM
                    </p>
                  </Col>
                  <Col className="mt-2" xs="12">
                    <p style={{ fontSize: '1.4rem', fontWeight: 700, color: '#FF0000' }}>
                      All customers are required to show the credit card used and government issued
                      identification upon pick up or delivery. Tito&apos;s Tacos will record your
                      driver's license number. By continuing you agree to these terms.
                    </p>
                  </Col>
                  <Col className="mt-4" xs="12">
                    <p>
                      <a
                        href="mailto:quality@streetsmartmessengers.com"
                        style={{ color: '#FF0000' }}
                      >
                        For questions, comments, or concerns about delivery, please click here to
                        contact StreetSmart Messengers
                      </a>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderOrderForm() {
    const { orderType } = this.state;

    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="sticky">
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row className="justify-content-lg-end justify-content-center">
                  <Col lg="4" xs="12" className="readout-title-order">
                    ORDER
                  </Col>
                  <Col lg="4" md="5" xs="10">
                    <div className="cart">
                      CART ({this.state.totalItems}) ${Math.abs(this.state.totalCost).toFixed(2)}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="item-top">
                  <Col xs="12">
                    <h2>Food</h2>
                    <p className="text-center" style={{ fontWeight: 700 }}>
                      Food orders come with FREE Tortilla Chips & Salsa
                    </p>
                  </Col>
                </Row>
                {this.renderMenuItems('food')}
                <Row className="item">
                  <Col xs="12">
                    <h2>Drinks</h2>
                  </Col>
                </Row>
                {orderType === 'pickup' && this.renderMenuItems('drink')}
                {orderType === 'delivery' && this.renderMenuItems('deliveryDrink')}
                {this.renderMenuItems('water')}
                <Row className="item">
                  <Col xs="12">
                    <h2>Merchandise</h2>
                  </Col>
                </Row>
                {this.renderMenuItems('merch')}
                <Row className="item">
                  <Col xs="12">
                    <h3>* All prices Subject to Change without Notice</h3>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer">
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="footer-button">
                <Row>
                  <Col xs="6" className="footer-button-back">
                    <Button sz="sm" onClick={(e) => this.onStartOverBtnClick(e)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} /> BACK
                    </Button>
                  </Col>
                  <Col xs="6">
                    <Button
                      sz="sm"
                      onClick={(e) => this.onContinueBtnClick(e, 'orderConfirmation')}
                    >
                      CONTINUE
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderOrderConfirmation() {
    const {
      orderType,
      totalCost,
      totalItems,
      orderSlotsFull,
      closestTimes,
      pickupTimeInvalid,
      pickupTimeSelected,
      pickupDateTime,
    } = this.state;

    // set variables
    let today = new Date();
    let firstOrder = set(pickupDateTime, { hours: 11, minutes: 15, seconds: 0, milliseconds: 0 });
    const lastOrder = set(pickupDateTime, { hours: 19, minutes: 0, seconds: 0, milliseconds: 0 });
    const thanksgiving = new Date(2024, 10, 28);
    const christmasEve = new Date(2024, 11, 24);
    const christmas = new Date(2024, 11, 25);

    const isHoliday =
      isSameDay(pickupDateTime, thanksgiving) ||
      isSameDay(pickupDateTime, christmasEve) ||
      isSameDay(pickupDateTime, christmas);

    // check if all slots are taken for the day
    const noMoreSlots = orderSlotsFull && closestTimes.length === 0;

    // add one day to today if restaurant is closed
    if (isAfter(today, set(today, { hours: 18, minutes: 30, seconds: 0, milliseconds: 0 }))) {
      today = addDays(today, 1);
    }

    // setup dateArray
    const dateArr = [];
    for (let i = 0; i < 15; i += 1) {
      dateArr.push(today);
      today = addDays(today, 1);
    }

    // if pickupDateTime is today and restaurant is currently open
    // update firstOrder so we only list times in the future
    if (
      isToday(pickupDateTime) &&
      isBefore(firstOrder, roundToNearestMinutes(addMinutes(new Date(), 38), { nearestTo: 15 }))
    ) {
      firstOrder = roundToNearestMinutes(addMinutes(new Date(), 38), { nearestTo: 15 });
    }

    // setup timeArray
    const timeArr = [];
    while (firstOrder <= lastOrder) {
      timeArr.push(firstOrder);
      firstOrder = addMinutes(firstOrder, 15);
    }

    const pickupDate = format(pickupDateTime, 'yyyy-M-d');
    const pickupTime = format(pickupDateTime, 'H:mm');
    const pickupTimeNotInList = getMinutes(pickupDateTime) % 15 !== 0;

    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">CONFIRM</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="item-top">
                  <Col xs="12">
                    <h4>{orderType === 'pickup' ? 'Pick Up' : 'Delivery'}</h4>
                    {orderType === 'pickup' && (
                      <p>
                        Your food order will be ready at the Tito’s Tacos Express Window located in
                        front of the Restaurant at the time selected. Please be on time and do not
                        be late.
                      </p>
                    )}
                    {orderType === 'delivery' && (
                      <p>
                        Your food order will be picked up by{' '}
                        <span style={{ fontWeight: 700 }}>StreetSmart Messengers</span> at the time
                        selected and delivered inside of approximately thirty minutes within five
                        miles of Tito’s Tacos. Please allow additional time for deliveries further
                        than five miles, in addition to special consideration for delays caused by
                        unpredictable traffic conditions.
                      </p>
                    )}
                  </Col>
                  <Col xs={{ size: 10, offset: 1 }}>
                    <Row className="pick-up justify-content-center">
                      <Col md="4" xs="12">
                        <FormGroup>
                          <Label for="pickupDate">Date</Label>
                          <Input
                            type="select"
                            name="pickupDate"
                            id="pickupDate"
                            value={pickupDate}
                            onChange={(e) => this.handlePickupDateChange(e)}
                          >
                            {dateArr.map((date) => (
                              <option key={date} value={format(date, 'yyyy-M-d')}>
                                {format(date, 'M/dd')}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4" xs="12">
                        <FormGroup>
                          <Label for="pickupTime">Time</Label>
                          <Input
                            type="select"
                            name="pickupTime"
                            id="pickupTime"
                            value={pickupTime}
                            onChange={(e) => this.handlePickupTimeChange(e)}
                          >
                            {pickupTimeNotInList && (
                              <option key={pickupTime} value={pickupTime}>
                                {format(pickupDateTime, 'h:mm a')}
                              </option>
                            )}
                            {timeArr.map((time) => (
                              <option key={time} value={format(time, 'H:mm')}>
                                {format(time, 'h:mm a')}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    {isHoliday && (
                      <Row className="select-new-time">
                        <Col className="no-times-available">
                          <p>Closed for the holiday, please select a different date to continue.</p>
                        </Col>
                      </Row>
                    )}
                    {pickupTimeInvalid && (
                      <Row className="select-new-time">
                        <Col className="no-times-available">
                          <p>
                            The selected time was not available, please select a new pickup time.
                          </p>
                        </Col>
                      </Row>
                    )}
                    {noMoreSlots && (
                      <Row className="select-new-time">
                        <Col className="no-times-available">
                          <p>
                            Sorry, there are no {orderType === 'pickup' ? 'pick up' : 'delivery'}{' '}
                            times available for the date selected. Please select a different date to
                            continue.
                          </p>
                        </Col>
                      </Row>
                    )}
                    {!noMoreSlots && orderSlotsFull && (
                      <Row className="select-new-time">
                        <Col>
                          <p>
                            The selected time was not available, please select a new pickup time:
                          </p>
                          <Row className="justify-content-center">
                            {closestTimes.map((closestTime) => (
                              <Col key={closestTime.time} lg="3" xs="6">
                                <Button
                                  sz="sm"
                                  value={format(closestTime.time, 'H:mm')}
                                  onClick={(e) => this.handlePickupTimeChange(e)}
                                  block
                                  active={pickupTime === format(closestTime.time, 'H:mm')}
                                >
                                  {format(closestTime.time, 'h:mm a')}
                                </Button>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="item">
                  <Col xs="12">
                    <h4>Your Order</h4>
                  </Col>
                </Row>
                {this.renderCart()}
                <Row className="item-even">
                  <Col md={{ size: 4, offset: 4 }} xs={{ size: 6, offset: 3 }}>
                    <div className="order-total">
                      <div className="order-total-title">
                        ORDER {orderType === 'pickup' ? 'TOTAL' : 'SUBTOTAL'}
                      </div>
                      <div className="order-total-amount">${Math.abs(totalCost).toFixed(2)}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer">
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="footer-button">
                <Row>
                  <Col xs="6" className="footer-button-back">
                    <Button sz="sm" onClick={(e) => this.onContinueBtnClick(e, 'orderForm')}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} /> BACK
                    </Button>
                  </Col>
                  <Col xs="6">
                    <Button
                      sz="sm"
                      disabled={!(totalItems > 0) || !pickupTimeSelected || isHoliday}
                      onClick={(e) => this.validateOrderTime(e)}
                    >
                      CONFIRM
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderPickupCheckout() {
    const selectStyle = {
      height: '48px',
      border: '2px solid #000000',
      borderRadius: 0,
      fontWeight: 700,
    };

    const {
      firstName,
      lastName,
      billingAddress,
      billingCity,
      billingState,
      billingZip,
      phone,
      email,
      cardNumber,
      cardValidThruMonth,
      cardValidThruYear,
      cardCVC,
      totalCost,
      formErrors,
      paymentDeclined,
      disableSubmitBtn,
    } = this.state;

    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">CHECKOUT</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="item-top">
                  <Col xs="12">
                    <h4>Customer Info</h4>
                  </Col>
                  {paymentDeclined && (
                    <Col xs="12" className="payment-declined">
                      <p>Payment Declined. Please check all information and try again.</p>
                    </Col>
                  )}
                </Row>
                <Row className="checkout">
                  <Col className="col-padding-bottom" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.firstName}
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        placeholder="First Name"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid name</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.lastName}
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        placeholder="Last Name"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid name</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col className="col-padding-bottom" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.phone}
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        placeholder="Phone"
                        onChange={this.handlePhoneInputChange}
                        onBlur={this.handlePhoneInputBlur}
                      />
                      <FormFeedback>Please enter a valid phone number</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.email}
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid email address</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="item-even">
                  <Col xs="12">
                    <h4>Payment Info</h4>
                    <p>
                      We accept all major credit / debit cards. You must present the Payment Card
                      and Photo Identification when picking up your order.{' '}
                      <span style={{ fontWeight: 700 }}>
                        Please make sure to enter your billing address!
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row className="checkout-even">
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.billingAddress}
                        type="text"
                        id="billingAddress"
                        name="billingAddress"
                        value={billingAddress}
                        placeholder="Address"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid address</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout-even">
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.billingCity}
                        type="text"
                        id="billingCity"
                        name="billingCity"
                        value={billingCity}
                        placeholder="City"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid city</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout-even">
                  <Col md="6" xs="8">
                    <Input
                      type="select"
                      style={selectStyle}
                      id="billingState"
                      name="billingState"
                      value={billingState}
                      placeholder="State"
                      onChange={this.handleInputChange}
                    >
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </Input>
                  </Col>
                  <Col md="6" xs="4">
                    <FormGroup>
                      <Input
                        invalid={formErrors.billingZip}
                        type="text"
                        id="billingZip"
                        name="billingZip"
                        value={billingZip}
                        placeholder="ZIP"
                        onChange={this.handleZipInputChange}
                      />
                      <FormFeedback>Please enter a valid zip code</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout-even">
                  <Col className="col-padding-bottom" md="5" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardNumber}
                        type="text"
                        id="cardNumber"
                        name="cardNumber"
                        value={cardNumber}
                        placeholder="Card Number"
                        onChange={this.handleCardNumberInputChange}
                      />
                      <FormFeedback>Please enter a valid card number</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="2" xs="3">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardValidThruMonth}
                        type="text"
                        id="cardValidThruMonth"
                        name="cardValidThruMonth"
                        value={cardValidThruMonth}
                        placeholder="MM"
                        onChange={this.handleCardValidThruInputChange}
                        onBlur={this.handleCardValidThruInputBlur}
                      />
                      <FormFeedback>Please enter a valid month in MM format</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="2" xs="3">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardValidThruYear}
                        type="text"
                        id="cardValidThruYear"
                        name="cardValidThruYear"
                        value={cardValidThruYear}
                        placeholder="YY"
                        onChange={this.handleCardValidThruInputChange}
                        onBlur={this.handleCardValidThruInputBlur}
                      />
                      <FormFeedback>Please enter a valid year in YY format</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="3" xs="6">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardCVC}
                        type="text"
                        id="cardCVC"
                        name="cardCVC"
                        value={cardCVC}
                        placeholder="CVC"
                        onChange={this.handleCVCInputChange}
                      />
                      <FormFeedback>Please enter a valid cvc</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="item">
                  <Col md={{ size: 4, offset: 4 }} xs={{ size: 6, offset: 3 }}>
                    <div className="order-total">
                      <div className="order-total-title">ORDER TOTAL</div>
                      <div className="order-total-amount">${Math.abs(totalCost).toFixed(2)}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer">
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="footer-button">
                <Row>
                  <Col xs="6" className="footer-button-back">
                    <Button
                      sz="sm"
                      onClick={(e) => this.onContinueBtnClick(e, 'orderConfirmation')}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} /> BACK
                    </Button>
                  </Col>
                  <Col xs="6">
                    <Button
                      sz="sm"
                      disabled={disableSubmitBtn}
                      onClick={(e) => this.submitOrder(e)}
                    >
                      CHECKOUT
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderDeliveryAddressCheckout() {
    const selectStyle = {
      height: '48px',
      border: '2px solid #000000',
      borderRadius: 0,
      fontWeight: 700,
    };

    const {
      deliveryFirstName,
      deliveryLastName,
      deliveryAddress,
      deliveryAddress2,
      deliveryCity,
      deliveryState,
      deliveryZip,
      phone,
      email,
      deliveryInstructions,
      contactlessDeliveryCheckbox,
      totalCost,
      deliveryTip,
      formErrors,
      invalidAddress,
    } = this.state;

    if (Object.keys(formErrors).length !== 0) {
      window.scrollTo(0, 0);
    }

    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">CHECKOUT</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="item-top">
                  <Col xs="12">
                    <h4>Delivery Info</h4>
                  </Col>
                  {invalidAddress && (
                    <Col xs="12" className="payment-declined">
                      <p>
                        Address could not be verified for delivery or is outside the current
                        delivery area. Please check all information and try again.
                      </p>
                    </Col>
                  )}
                </Row>
                <Row className="checkout">
                  <Col className="col-padding-bottom" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.deliveryFirstName}
                        type="text"
                        id="deliveryFirstName"
                        name="deliveryFirstName"
                        value={deliveryFirstName}
                        placeholder="First Name"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid name</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.deliveryLastName}
                        type="text"
                        id="deliveryLastName"
                        name="deliveryLastName"
                        value={deliveryLastName}
                        placeholder="Last Name"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid name</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col className="col-padding-bottom" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.phone}
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        placeholder="Phone"
                        onChange={this.handlePhoneInputChange}
                        onBlur={this.handlePhoneInputBlur}
                      />
                      <FormFeedback>Please enter a valid phone number</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.email}
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid email address</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.deliveryAddress}
                        type="text"
                        id="deliveryAddress"
                        name="deliveryAddress"
                        value={deliveryAddress}
                        placeholder="Address"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid address</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        type="text"
                        id="deliveryAddress2"
                        name="deliveryAddress2"
                        value={deliveryAddress2}
                        placeholder="Apartment, Unit, Suite, etc. (Optional)"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid address</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.deliveryCity}
                        type="text"
                        id="deliveryCity"
                        name="deliveryCity"
                        value={deliveryCity}
                        placeholder="City"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid city</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col md="6" xs="8">
                    <Input
                      disabled
                      type="select"
                      style={selectStyle}
                      id="deliveryState"
                      name="deliveryState"
                      value={deliveryState}
                      placeholder="State"
                      onChange={this.handleInputChange}
                    >
                      <option value="CA">California</option>
                    </Input>
                  </Col>
                  <Col md="6" xs="4">
                    <FormGroup>
                      <Input
                        invalid={formErrors.deliveryZip}
                        type="text"
                        id="deliveryZip"
                        name="deliveryZip"
                        value={deliveryZip}
                        placeholder="ZIP"
                        onChange={this.handleZipInputChange}
                      />
                      <FormFeedback>Please enter a valid zip code</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="delivery-special-instructions">
                  <Col xs={{ size: 10, offset: 1 }}>
                    <div className="order-total">
                      <div className="order-total-title">Special Instructions for Delivery</div>
                      <p>
                        Provide helpful information to accelerate delivery of your order, such as
                        Studio Drive-On Pass Info, Gate & Security Access Codes, Delivery Location
                        Drop-Off, etc.
                      </p>
                      <div>
                        <FormGroup>
                          <Input
                            type="textarea"
                            className="delivery-special-instructions-input"
                            id="deliveryInstructions"
                            name="deliveryInstructions"
                            rows="2"
                            maxLength="250"
                            value={deliveryInstructions}
                            placeholder="max 250 characters"
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                        {/* <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="contactlessDeliveryCheckbox"
                              checked={contactlessDeliveryCheckbox}
                              value={contactlessDeliveryCheckbox}
                              onChange={this.handleInputChange}
                            />{' '}
                            <span style={{ fontWeight: 700 }}>
                              Check here for contactless delivery. Driver will leave your order in a
                              secure location.
                            </span>
                          </Label>
                        </FormGroup> */}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="item">
                  <Col md={{ size: 4, offset: 4 }} xs={{ size: 6, offset: 3 }}>
                    <div className="order-total">
                      <div className="order-total-title">DELIVERY TIP</div>
                      <Input
                        className="order-total-amount"
                        style={{ textAlign: 'center', color: '#000000' }}
                        type="text"
                        id="deliveryTip"
                        name="deliveryTip"
                        value={deliveryTip}
                        onChange={this.handleTipInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs="12">
                    <p className="text-center">
                      Add an optional tip for your{' '}
                      <span style={{ fontWeight: 700 }}>StreetSmart Messengers</span> driver.
                    </p>
                  </Col>
                </Row>
                <Row className="item-even">
                  <Col md={{ size: 4, offset: 4 }} xs={{ size: 6, offset: 3 }}>
                    <div className="order-total">
                      <div className="order-total-title">ORDER SUBTOTAL</div>
                      <div className="order-total-amount">${Math.abs(totalCost).toFixed(2)}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer">
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="footer-button">
                <Row>
                  <Col xs="6" className="footer-button-back">
                    <Button
                      sz="sm"
                      onClick={(e) => this.onContinueBtnClick(e, 'orderConfirmation')}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} /> BACK
                    </Button>
                  </Col>
                  <Col xs="6">
                    <Button sz="sm" onClick={(e) => this.calculateDeliveryCost(e)}>
                      CONTINUE
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderDeliveryPaymentCheckout() {
    const selectStyle = {
      height: '48px',
      border: '2px solid #000000',
      borderRadius: 0,
      fontWeight: 700,
    };

    const {
      firstName,
      lastName,
      billingAddressCheckbox,
      billingAddress,
      billingCity,
      billingState,
      billingZip,
      cardNumber,
      cardValidThruMonth,
      cardValidThruYear,
      cardCVC,
      formErrors,
      paymentDeclined,
      disableSubmitBtn,
    } = this.state;

    let { totalCost, deliveryFee, deliveryTip } = this.state;

    totalCost = parseFloat(totalCost).toFixed(2);
    deliveryFee = parseFloat(deliveryFee).toFixed(2);
    deliveryTip = parseFloat(deliveryTip).toFixed(2);

    const orderTotal = parseFloat(totalCost) + parseFloat(deliveryFee) + parseFloat(deliveryTip);

    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">CHECKOUT</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="item-top">
                  <Col xs="12">
                    <h4>Payment Info</h4>
                    {paymentDeclined && (
                      <Col xs="12" className="payment-declined">
                        <p>Payment Declined. Please check all information and try again.</p>
                      </Col>
                    )}
                    <p>
                      We accept all major credit / debit cards.{' '}
                      <span style={{ fontWeight: 700, color: '#FF0000' }}>
                        The payment card holder MUST BE PRESENT
                      </span>{' '}
                      and show the payment card and photo identification for verification upon
                      delivery.{' '}
                      <span style={{ fontWeight: 700 }}>
                        Please make sure to enter your billing address!
                      </span>
                    </p>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="billingAddressCheckbox"
                          checked={billingAddressCheckbox}
                          value={billingAddressCheckbox}
                          onChange={this.handleBillingCheckboxChange}
                        />
                        Billing address is the same as delivery address.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col className="col-padding-bottom" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.firstName}
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        placeholder="First Name"
                        disabled
                      />
                      <FormFeedback>Please enter a valid name</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="6" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.lastName}
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        placeholder="Last Name"
                        disabled
                      />
                      <FormFeedback>Please enter a valid name</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        disabled={billingAddressCheckbox}
                        invalid={formErrors.billingAddress}
                        type="text"
                        id="billingAddress"
                        name="billingAddress"
                        value={billingAddress}
                        placeholder="Address"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid address</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col xs="12">
                    <FormGroup>
                      <Input
                        disabled={billingAddressCheckbox}
                        invalid={formErrors.billingCity}
                        type="text"
                        id="billingCity"
                        name="billingCity"
                        value={billingCity}
                        placeholder="City"
                        onChange={this.handleInputChange}
                      />
                      <FormFeedback>Please enter a valid city</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col md="6" xs="8">
                    <Input
                      disabled={billingAddressCheckbox}
                      type="select"
                      style={selectStyle}
                      id="billingState"
                      name="billingState"
                      value={billingState}
                      placeholder="State"
                      onChange={this.handleInputChange}
                    >
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </Input>
                  </Col>
                  <Col md="6" xs="4">
                    <FormGroup>
                      <Input
                        disabled={billingAddressCheckbox}
                        invalid={formErrors.billingZip}
                        type="text"
                        id="billingZip"
                        name="billingZip"
                        value={billingZip}
                        placeholder="ZIP"
                        onChange={this.handleZipInputChange}
                      />
                      <FormFeedback>Please enter a valid zip code</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="checkout">
                  <Col className="col-padding-bottom" md="5" xs="12">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardNumber}
                        type="text"
                        id="cardNumber"
                        name="cardNumber"
                        value={cardNumber}
                        placeholder="Card Number"
                        onChange={this.handleCardNumberInputChange}
                      />
                      <FormFeedback>Please enter a valid card number</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="2" xs="3">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardValidThruMonth}
                        type="text"
                        id="cardValidThruMonth"
                        name="cardValidThruMonth"
                        value={cardValidThruMonth}
                        placeholder="MM"
                        onChange={this.handleCardValidThruInputChange}
                        onBlur={this.handleCardValidThruInputBlur}
                      />
                      <FormFeedback>Please enter a valid month in MM format</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="2" xs="3">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardValidThruYear}
                        type="text"
                        id="cardValidThruYear"
                        name="cardValidThruYear"
                        value={cardValidThruYear}
                        placeholder="YY"
                        onChange={this.handleCardValidThruInputChange}
                        onBlur={this.handleCardValidThruInputBlur}
                      />
                      <FormFeedback>Please enter a valid year in YY format</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="col-padding-top" md="3" xs="6">
                    <FormGroup>
                      <Input
                        invalid={formErrors.cardCVC}
                        type="text"
                        id="cardCVC"
                        name="cardCVC"
                        value={cardCVC}
                        placeholder="CVC"
                        onChange={this.handleCVCInputChange}
                      />
                      <FormFeedback>Please enter a valid cvc</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="item-even align-items-center justify-content-center">
                  <Col md="4" xs="6">
                    <div className="order-total">
                      <div className="order-total-title">ORDER SUBTOTAL</div>
                      <div className="order-total-amount">${Math.abs(totalCost).toFixed(2)}</div>
                    </div>
                  </Col>
                  <Col md="4" xs="6">
                    <div className="order-total">
                      <div className="order-total-title">DELIVERY TIP</div>
                      <div className="order-total-amount">${Math.abs(deliveryTip).toFixed(2)}</div>
                    </div>
                  </Col>
                  <Col md="4" xs="6">
                    <div className="order-total">
                      <div className="order-total-title">DELIVERY FEE</div>
                      <div className="order-total-amount">${Math.abs(deliveryFee).toFixed(2)}</div>
                    </div>
                  </Col>
                  <Col md="4" xs="6">
                    <div className="order-total">
                      <div className="order-total-title">ORDER TOTAL</div>
                      <div className="order-total-amount">${Math.abs(orderTotal).toFixed(2)}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer">
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="footer-button">
                <Row>
                  <Col xs="6" className="footer-button-back">
                    <Button sz="sm" onClick={(e) => this.onContinueBtnClick(e, 'checkout')}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} /> BACK
                    </Button>
                  </Col>
                  <Col xs="6">
                    <Button
                      sz="sm"
                      disabled={disableSubmitBtn}
                      onClick={(e) => this.submitOrder(e)}
                    >
                      CHECKOUT
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderOrderComplete() {
    const { orderType, pickupDateTime, orderNumber, confirmationEmailSent } = this.state;

    if (!confirmationEmailSent) {
      this.sendEmailConfirmation();
    }

    return (
      <div>
        <div className="header">
          <Container>
            <Row>
              <Col xs="12" className="tops" />
            </Row>
            <Row>
              <Col xs="12">
                <h1>Tito's Tacos Express</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="readout">
                <Row>
                  <Col className="readout-title">THANK YOU FOR YOUR ORDER!</Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="pt-3">
                <Row>
                  <Col lg="5" xs="12">
                    <div className="order-number">
                      <div className="order-number-title">ORDER NUMBER</div>
                      <div className="order-number-readout">{orderNumber}</div>
                    </div>
                  </Col>
                  <Col lg="7" xs="12">
                    <div className="order-number">
                      <div className="order-number-title">PICK UP TIME</div>
                      <div className="order-number-readout">
                        {format(pickupDateTime, 'MM/dd - h:mm a')}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={{ size: 10, offset: 1 }} xs="12" className="menu">
                <Row className="item-top">
                  <Col xs="12">
                    {orderType === 'pickup' && (
                      <div>
                        <p>
                          You may pick up your order at the Tito&apos;s Tacos Express Window. Please
                          have the Payment Card and Photo Identification ready.
                        </p>
                        {/* <p>
                          If you have any issues, please call us at{' '}
                          <a href="tel:3103915780">
                            <b>310-391-5780</b>
                          </a>
                          .
                        </p> */}
                      </div>
                    )}
                    {orderType === 'delivery' && (
                      <div>
                        <p>
                          <span style={{ fontWeight: 700 }}>StreetSmart Messengers</span> will pick
                          up your order at the time selected. Your order will arrive within 30
                          minutes after pick up for deliveries within 5 miles. Please allow
                          additional time for deliveries greater than 5 miles.
                        </p>
                        <p>
                          <span style={{ fontWeight: 700 }}>
                            Be prepared to show ID and credit card for verification upon delivery!
                          </span>
                        </p>
                        <p>
                          <a
                            href="mailto:quality@streetsmartmessengers.com"
                            style={{ color: '#FF0000' }}
                          >
                            For questions, comments, or concerns about delivery, please click here
                            to contact StreetSmart Messengers
                          </a>
                        </p>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  render() {
    const { orderType, currentView } = this.state;
    if (currentView === 'loading') {
      return <div>{this.renderLoading()}</div>;
    }
    if (currentView === 'maintenance') {
      return <div>{this.renderDownForMaintenance()}</div>;
    }
    if (currentView === 'orderType') {
      return <div>{this.renderSelectOrderType()}</div>;
    }
    if (currentView === 'orderForm') {
      return <div>{this.renderOrderForm()}</div>;
    }
    if (currentView === 'orderConfirmation') {
      return <div>{this.renderOrderConfirmation()}</div>;
    }
    if (orderType === 'pickup' && currentView === 'checkout') {
      return <div>{this.renderPickupCheckout()}</div>;
    }
    if (orderType === 'delivery' && currentView === 'checkout') {
      return <div>{this.renderDeliveryAddressCheckout()}</div>;
    }
    if (orderType === 'delivery' && currentView === 'payment') {
      return <div>{this.renderDeliveryPaymentCheckout()}</div>;
    }
    if (currentView === 'orderComplete') {
      return <div>{this.renderOrderComplete()}</div>;
    }
    if (currentView === 'orderError') {
      return <div>{this.renderOrderError()}</div>;
    }
    return <div>{this.renderDownForMaintenance()}</div>;
  }
}

export default Home;
